import VueRouter from 'vue-router'
import ky from 'ky'

const MapQuest_KEY = "frWnGmgOv5ZT70Hzhf6VQRrbDvdYm2sc"

export class Search {
    /** 
     * @param {string} q
     * @param {VueRouter} r
     */
    static async route(q, r) {
        //Get coordinates
        if ((q || "").trim() == "") return;
        /** @type {any[]} */
        var locations = (await ky.get("https://www.mapquestapi.com/geocoding/v1/address", {
            searchParams: {
                key: MapQuest_KEY,
                location: q
            }
        }).json()).results[0].locations
        if (locations.length == 0) throw "Location Not Found";
        // Push to coordinates
        let location = locations[0]
        let lat = parseFloat(Number(location.latLng.lat).toFixed(3)).toString()
        let lng = parseFloat(Number(location.latLng.lng).toFixed(3)).toString()
        console.log(lat, lng)
        var gridPoints;
        //Try to match coordinates to grid points
        try {
            gridPoints = (await ky.get(`https://api.weather.gov/points/${lat},${lng}`).json()).properties
        }
        catch (err) {
            if (err.message == 404) throw "Locations outside of the United States are not yet supported"
        }
        //Validate grid points
        if (!gridPoints.gridId || !gridPoints.gridX || !gridPoints.gridY) throw "Weather data not available at this location"
        r.push(`/w/${gridPoints.gridId}/${gridPoints.gridX}/${gridPoints.gridY}`)
    }

    static async weather(office, x, y) {
        //Get weather
        var weather;
        try {
            weather = await ky.get(`https://api.weather.gov/gridpoints/${office}/${x},${y}`).json()
        } catch (err) {
            throw "Failed to get weather at this location"
        }
        //Get forecast
        var forecast;
        try {
            forecast = await ky.get(`https://api.weather.gov/gridpoints/${office}/${x},${y}/forecast/hourly`).json()
        } catch (err) {
            throw "Failed to get forecast at this location"
        }
        //Get location info
        let coordArray = forecast.geometry.coordinates[0]
        var lats = 0
        var longs = 0
        for (var coord of coordArray) {
            lats += coord[1]
            longs += coord[0]
        }
        let coords = `${(lats/coordArray.length).toFixed(4)},${(longs/coordArray.length).toFixed(4)}`
        var location;
        try {
            location = (await ky.get("https://open.mapquestapi.com/geocoding/v1/reverse",{
                searchParams: {
                    key: MapQuest_KEY,
                    location: coords
                }
            }).json())
        } catch (err) {
            throw "Location not found"
        }
        //Validate Results
        if (location.results.length == 0 || location.results[0].locations.length == 0) throw "Location not found";
        location = location.results[0].locations[0]
        var area = {}
        for (var key of Object.keys(location).filter(k => k.startsWith("adminArea") && k.endsWith("Type"))) {
            area[location[key].toLowerCase()] = location[key.replace("Type","")]
        }
        console.log(area)
        //Return
        return [weather,forecast,area,coords]
    }
}