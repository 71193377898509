<template>
  <div id="app">
    <MjToast ref="toast" />
    <div class="w-loading-over" v-if="loading" />
    <div class="w-nav-bar w-border-bottom">
      <MjInput
        class="search"
        @keyup.enter="search"
        v-model="query"
        placeholder="address, city or zip code"
      >
        <template #icon>
          <MjIcon name="search" />
        </template>
      </MjInput>
      <img src="@/assets/logo.png" @click="$router.push('/')" class="logo" />
    </div>
    <router-view class="w-router-view" />
  </div>
</template>

<script>
import { Search } from "./js/search";
import Loading from "vue-loading-overlay";

export default {
  name: "App",
  components: { Loading },
  data() {
    return {
      query: null,
      loading: false,
    };
  },
  methods: {
    async search() {
      this.loading = true;
      try {
        await Search.route(this.query, this.$router);
      } catch (err) {
          this.$refs.toast.error(err)
      }
      this.loading = false;
    },
  },
};
</script>


