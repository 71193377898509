<template>
  <div class="home" align="center">
    <div class="w-bg-image" />
    <div class="w-triangle" />
    <search-box style="margin-top: -70pt" />
    <div
      class="w-content"
      style="margin-top: 64pt; margin-bottom: 64pt"
      align="left"
    >
      <div class="w-home-icon-spaced" align="center">
        <MjIcon name="chevron-down" />
      </div>

      <h2>🌦 Accurate Predictions</h2>
      <p>
        Other weather websites are <i>lying</i> to you. Our source (who wishes
        to remain anonymous) told us that a popular weather website,
        <a href="https://react-weather-site.vercel.app/">Optimum Weather Site</a
        >, was providing incorrect weather data. We at Superlative Weather Site
        would never. Imagine going outside because the weather prediction said
        it would be sunny and there turns out to be a tornado. You would die.
        <i>Optimal Weather Site</i> literally wants you dead. Just let that sink
        in.
      </p>
      <MjDivider style="margin: 40pt 60pt" />
      <h2>📍 Precise Locations</h2>
      <p>
        Our source also made us aware that <i>Optimum Weather Site</i> has a
        gigantic flaw when it comes to gathering locations. Try entering
        '97219', a ZIP Code in Portland, OR. Why does it give you the weather in
        Mérida, MX? Seems like a pretty glaring oversite. Superlative Weather
        Site uses superior location searching algorithms to serve you the most
        accurate results.
      </p>
      <MjDivider style="margin: 40pt 60pt" />
      <h2>📱 Newest Technologies</h2>
      <p>
        Superlative Weather Site uses the newest web technologies like Vue,
        Mapbox, and NOAA APIs to deliver you forecasts quickly and accurately.
        Unlike other weather websites, which might use outdated technologies
        like React.
      </p>
    </div>
  </div>
</template>

<script>
import SearchBox from "../components/SearchBox.vue";

export default {
  components: { SearchBox },
  props: ["loading"],
  name: "Home",
};
</script>
