<template>
  <div class="w-search-box" align="left">
      <MjToast ref="toast" />
    <div class="w-loading-over" v-if="loading" />
    <h3>Enter Location</h3>
    <small>united states only</small>
    <MjInput
      v-model="query"
      @keyup.enter="search"
      class="input"
      placeholder="address, city or zip code"
    >
      <template #icon>
        <MjIcon name="search" />
      </template>
    </MjInput>
    <div align="right" style="margin-top: 2pt">
      <MjButton @click="search" icon-right style="margin-top: 15pt">
        <template #icon>
          <MjIcon name="arrow-right" />
        </template>
        Get Forecast
      </MjButton>
    </div>
  </div>
</template>

<script>
import { Search } from "../js/search";

export default {
  name: "w-search-box",
  data() {
    return {
      query: null,
      loading: false,
    };
  },
  methods: {
    async search() {
      this.loading = true;
      try {
        await Search.route(this.query, this.$router);
      } catch (err) {
          this.$refs.toast.error(err)
      }
      this.loading = false;
    },
  },
};
</script>

<style>
</style>