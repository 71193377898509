import Vue from 'vue'
import App from './App.vue'
import router from './router'

import './css/index.css'
import Mijin from 'mijin'

import moment from 'moment'
import 'moment-timezone'

Vue.use(Mijin)

Vue.config.productionTip = false

Vue.prototype.$f = (c) => {
  return ((c * 9/5) + 32).toString()
}

const timezoneNames = {
  '-10':'Pacific/Honolulu',
  '-08':'America/Anchorage',
  '-07':'America/Los_Angeles',
  '-06':'America/Boise',
  '-05':'America/Chicago',
  '-04':'America/New_York',
}

/** 
 * @param {string} tz
 * @param {string} t 
 */
Vue.prototype.$d = (t,tz) => {
  let offset = tz.substring(19,22)
  //let time = new Date(t)
  let timezone = timezoneNames[offset]
  console.log(offset,timezone)
  return new Date().toLocaleString('en-US', { timeZone: timezone, timeZoneName: "long", hour12: true, hour: "numeric", minute: "2-digit" })
}

Vue.prototype.$dt = (t) => {
  let offset = t.substring(19,22)
  let time = new Date(t)
  let timezone = timezoneNames[offset]
  console.log(offset,timezone)
  return time.toLocaleString('en-US', { timeZone: timezone, hour12: true, hour: "numeric", minute: "2-digit" }).replace(":00","")
}

Vue.prototype.$ad = (a) => {
  a = Number.parseInt(a)
  switch (true) {
    case (a < 30): return "N"
    case (a < 60): return "NW"
    case (a < 120): return "W"
    case (a < 150): return "SW"
    case (a < 210): return "S"
    case (a < 240): return "SE"
    case (a < 300): return "E"
    case (a < 330): return "NE"
    case (a < 360): return "N"
    default: return "D"
  }
}


new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
